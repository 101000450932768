import { useNavigate } from "react-router-dom";

function Button({text, url}) {

    const navigate = useNavigate()

    const _handleClick = ()=>{
        navigate(url)
    }

    return (
        <div onClick={_handleClick} style={
            {
                width:"300px",
                fontSize: "38px",
                lineHeight: "90%",
                backgroundImage: 'url("images/btn.png")',
            }
        } className="aspect-square mx-2 bg-center flex myriad-bold text-white items-center px-6 text-center p-3">
            <span className="uppercase">{text}</span>
        </div>
    );
}

export default Button
