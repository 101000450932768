import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

const Languages = ['en'];

i18n.use(Backend).use(initReactI18next)
  .init({
    fallbackLng: 'lt',
    whitelist: Languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: 'locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: false,
    }

  });


export default i18n;