import { useState, useEffect } from "react";

function ProgressiveImage({ placeholder, src, ...props }) {

    const [img, setImg] = useState(placeholder || src);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImg(src);
        };
      }, [src]);

    return (
        <img
      {...{ src: img, ...props }}
      alt={props.alt || ""}
      />
    );
}

export default ProgressiveImage;