import CategoryButton from "../../../components/CategoryButton";

import { useTranslation } from 'react-i18next'

function Categories({categories, setSelectedCategory, selectedCategory}) {

    const { t, i18n} = useTranslation()

    return (
        <div style={{
            scrollbarWidth:"none"
            }} className="w-full pt-5 pb-2 flex relative justify-start overflow-y-visible overflow-x-scroll items-center catDiv ">
                {/* <img src="/images/touch.png" width="32" className='animated' alt="hand" /> */}
                <CategoryButton setCatId={setSelectedCategory} selected={selectedCategory} color="#1F5687" catid={null} text={t("Visos kategorijos")}/>
                {categories.map((cat, index)=>{
                    return (
                        <CategoryButton setCatId={setSelectedCategory} selected={selectedCategory} color={cat.meta.hasOwnProperty('cat_color') ? cat.meta.cat_color : "#FFDE16"} catid={cat.id} key={index} text={cat.names[i18n.language.toLowerCase()]} img={`${process.env.REACT_APP_BACKEND_HOST}${cat.icon.url}`}/>
                    )
                })}
        </div>
    );
}

export default Categories;