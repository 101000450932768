import { MapContainer, TileLayer} from 'react-leaflet'
import { useEffect, useState, useContext} from 'react';
import GetCacheData from '../../../../helpers/GetData';

import { DisablilityContext } from '../../../../App'

import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import MapLoader from '../../components/MapLoader';
import ClassterMarkers from './ClassterMarkers';

function Map() {
    const endpoint = "/admin/places/api/getActivePlaces?only=85e8f0d3aef94f980a1ba16f94862d15"
    const [setLastPage, setActivePage, setActiveName] = useOutletContext();    
    const { t } = useTranslation()
    const [markers, setMarkers] = useState([]);
    let { disability, setDisability} = useContext(DisablilityContext);

    useEffect(() => {
        setLastPage('/')
        setActiveName(t('Klasteris'))
        setActivePage('/main/classter')
        GetCacheData(process.env.REACT_APP_BACKEND_HOST+endpoint, 'Classter').then(data=>{
            setMarkers(data.data)
        }).catch(()=>{
            console.log("Aptikta klaida su duomenų krovimu, prašome informuoti administraciją");
        });
    }, [setMarkers, setLastPage, setActivePage, setActiveName, t]);

    
    return (
        <>
            {/* <-----------map -------------/> */}
            <div className='w-full pt-4' style={{
                height:"1540px"
            }}>
                <div className='w-full pb-3 text-5xl text-center' style={{color:"#3072B9"}}>
                    {t('Pamario turizmo klasterio nariai')}
                </div>
                <MapContainer  style={{height:"1345px"}} minZoom={8} className="rounded-md" center={[55.55485537748448, 21.34834242595446]} zoom={10}>
                <MapLoader markers={markers}/>
                    <TileLayer
                    attribution=''
                    url="https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=Tzwx6Sh4T1b3h9jh9rwCuXjiwq2ag4nMxO9RrlWj37CSxdeNSxYTrwIRfUsVxVpY"
                    />
                    <ClassterMarkers markers={markers} setLastPage={setLastPage}/>
                </MapContainer>
                <div onClick={()=>{
                    setDisability(disability ? false : true);
                }} className='rounded-full w-24 flex justify-center items-center float-right mt-5 aspect-square' style={{
                    background:'#4190CE',
                    border: "6px solid white"
                }}>
                        <img src="/images/disabled.png" width={40} alt="disability" />
                </div>
            </div>
            
        </>
    );
}

export default Map;