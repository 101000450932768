function MapLoader({markers}) {
    return (
        <div className={`absolute flex w-full h-full bg-white items-center justify-center loader ${markers.length > 0 ? "loader-dissapear": null}`} style={{
            zIndex: 999,
            fontSize: "3em",
            pointerEvents: "none"
        }}>
            <img src="/images/loader.gif" alt="loading" />
        </div>
    );
}

export default MapLoader;