import TopWidget from "./components/TopWidget";
import MenuButton from './components/MenuButton'

import { useState, useEffect, useContext } from "react";
import moment from 'moment';
import axios from "axios";
import { Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { DisablilityContext } from '../../App'
import { useTranslation } from 'react-i18next'
import KioskBoard from 'kioskboard';
import { useRef } from "react";


function Home() {

    const { t, i18n} = useTranslation()
    const date = moment().format('YYYY MM DD');
    const [time, setTime] = useState(moment().format('HH:mm'));
    const [icon, setIcon] = useState("01.png");
    const [temp, setTemp] = useState(10);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState('/');
    const [activePage, setActivePage] = useState('/');
    const [activeName, setActiveName] = useState(t("Lankytinos vietos"));
    const [loading, setLoading] = useState(false);
    const [activeButton, setActiveButton] = useState(0);
    const navigate = useNavigate()
    let { disability } = useContext(DisablilityContext);
    const searchInputRef = useRef(null);
    
    const goBack = () => {
        navigate(lastPage);
    };

    useEffect(() => {
        const searchInput = searchInputRef.current;
        const search = ()=>{
            if(searchInput.value.length <=2) {
                setData([]);
            }
            if(!loading && searchInput.value.length > 2){
                setLoading(true);
                axios.get(`${process.env.REACT_APP_BACKEND_HOST}/admin/places/api/frontSearchPlaces?lang=${i18n.language.toLocaleLowerCase()}&term=${searchInput.value}&limit=5`).then(data => {
                    setLoading(false);
                    setData(data.data);
                    setLastPage(lastPage=>{
                        return lastPage;
                    });
                })
            }
        }

        searchInput.addEventListener("change", search);
        
        KioskBoard.init({
            language: 'en',
            theme: 'light',
            autoScroll: true,
            capsLockActive: false,
            allowRealKeyboard: true,
            keysSpacebarText: i18n.language === "lt" ? "Tarpas" : "Space",
            keysJsonUrl: '/kioskboard-keys-english.json'

        });
        KioskBoard.run('#searchInput');

        const getWeather = () => {
            const url = `https://api.openweathermap.org/data/2.5/weather?lat=55.70369056395698&lon=21.139349958742493&appid=e4633baf0f18b1956bf05a8e28c277b1&units=metric`
            axios.get(url).then(res => {
                const icon = res.data.weather[0].icon.replace('n', '').replace('d', '')
                setIcon(`${icon}.png`);
                setTemp(Math.ceil(res.data.main.temp))
            })
        }

        getWeather()

        let timeout;

        const goToOverlay = () => {
            navigate("/");    
        }
        const restartAutoReset = () => {
            if (timeout) {
              clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                goToOverlay();
            }, 120000);
          };
    
        const onActivity = () => {
            restartAutoReset();
        };
        
        restartAutoReset();

        window.addEventListener('mousemove', onActivity);
        window.addEventListener('touchstart', onActivity);

        const interval = setInterval(() => {
            getWeather();
            setTime(moment().format('HH:mm'))
        }, 60000);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);

            searchInput.removeEventListener("change", search);
            window.removeEventListener('mousemove', onActivity);
            window.removeEventListener('touchstart', onActivity);
        };
    }, [i18n, loading, setLoading, setData, setLastPage, navigate]);

    return (
        <>
        <div style={{
            width: "1080px",
            height: "1920px",
            backgroundImage: "url('/images/bg-white.jpg')"
        }} className="px-10">

            {disability ? <Outlet context={[setLastPage, setActivePage, setActiveName]}/>: null}
            {/* <-----------top widgets bar -------------/> */}
            <div className={`w-full ${disability ? "pt-12 text-white": "pt-16 text-black"} inline-flex  justify-around`}>
                <TopWidget disability={disability} text={date}/>
                <TopWidget disability={disability} icon={`/images/weather_icons/${icon}`} text={`${temp}°C`}/>
                <TopWidget disability={disability} text={time}/>
            </div>
            {/* <-----------Buttons bar -------------/> */}
            <div className={`w-full inline-flex justify-between ${disability ? "pt-12": "pt-16"}`}>
                <MenuButton bid={0} activeButton={activeButton} setActiveButton={setActiveButton} url={activePage} text={activeName}/>
                <MenuButton bid={1} activeButton={activeButton} setActiveButton={setActiveButton} url="/main/page/0c462396049555679d6cc2e" text={t("Priekulė")}/>
                <MenuButton bid={2} activeButton={activeButton} setActiveButton={setActiveButton} url="/main/page/fcded5d81343e22caa426ab6" text={t("Dreverna")}/>
                <MenuButton bid={3} activeButton={activeButton} setActiveButton={setActiveButton} url="/main/page/184980cf841003c71c36b05c" text={t("Svencelė")}/>
            </div>

            <div className="flex text-center items-center mt-4">
            <div onClick={goBack} style={{
                width: "70px",
                background:"#4190CE",
            }} className="aspect-square rounded-full flex font-medium justify-center text-4xl text-white">
                <img src="/images/undo.png" className="w-10 mx-auto -mt-1 self-center" alt="back" />
            </div>

            {/* <-----------search bar -------------/> */}
            <div className='flex grow ml-4 relative autocomplete'>
                <input type="text" id="searchInput" ref={searchInputRef} autoComplete="off" maxLength="20" className="w-full outline outline-1 outline-blue-500 text-xl rounded-full pl-7"
                style={{
                    height: "60px"
                }}
                name="search" placeholder={t("Paieška")}/>
                    <div style={{
                        top:0,
                        right:0,
                        height:"60px",
                        borderTopRightRadius: "50%",
                        borderBottomRightRadius: "50%",
                        background: "#4190CE"
                    }} className='aspect-square flex absolute'>
                        <button type="button" className='relative text-white m-auto text-2xl'>
                            <img src="/images/search.png" className="w-8 mx-auto -mt-1 self-center" alt="search" />
                        </button>
                    </div>
                <div className={`autocomplete-items ${disability? "disability" : null}`}>
                    {
                    data.length > 0 ? (
                        data.map((item, index) => <div onClick={()=>{
                            navigate(`/main/location/${item.place_id}`)
                            setLastPage(activePage)
                            setData([]);
                    }} key={index}>{item.name}</div>)
                    ) : null
                    }
                </div>
                </div>
            </div>
            {!disability ? <Outlet context={[setLastPage, setActivePage, setActiveName]}/>: null}
        </div>
        </>
    );
}

export default Home;