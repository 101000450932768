import { MapContainer, TileLayer} from 'react-leaflet'
import { useEffect, useState, useContext} from 'react';
import GetCacheData from '../../../../helpers/GetData';
import { DisablilityContext } from '../../../../App'

import 'leaflet/dist/leaflet.css';

import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import MapLoader from '../../components/MapLoader';

import AudioMarkers from './AudioMarkers';


function Map() {
    const endpoint = "/admin/paths/api/getPaths"

    const [markers, setMarkers] = useState([]);
    
    const { disability, setDisability } = useContext(DisablilityContext);
    const [setLastPage, setActivePage, setActiveName] = useOutletContext();    
    const {t} = useTranslation();
    
    useEffect(() => {
        setLastPage('/')
        setActivePage('/main/audio')
        setActiveName(t('Audio gidas'))
        try{
            GetCacheData(process.env.REACT_APP_BACKEND_HOST+endpoint, 'AudioMarkers').then(data=>{
                setMarkers(data.data)
            }).catch(()=>{
                console.log("error on loading markers")
            });
        } catch (error){
            console.log(error);
        }

    }, [setMarkers, setLastPage, setActivePage, setActiveName, t]);

    return (
        <>
            {/* <-----------map -------------/> */}
            <div className='w-full pt-4' style={{
                height:"1435px"
            }}>
                <div className='w-full pb-3 text-5xl text-center' style={{color:"#3072B9"}}>
                    {t('Audiogidas')}
                </div>
                <MapContainer style={{height:"1345px"}} minZoom={12} className="rounded-md" center={[55.55485537748448, 21.34834242595446]} zoom={12}>
                    <MapLoader markers={markers}/>
                    <TileLayer
                    attribution=''
                    url="https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=Tzwx6Sh4T1b3h9jh9rwCuXjiwq2ag4nMxO9RrlWj37CSxdeNSxYTrwIRfUsVxVpY"
                    />
                    <AudioMarkers markers={markers} setLastPage={setLastPage}/>
                </MapContainer>
                <div onClick={()=>{
                    setDisability(disability ? false : true);
                }} className='rounded-full w-24 flex justify-center items-center float-right mt-5 aspect-square' style={{
                    background:'#4190CE',
                    border: "6px solid white",
                }}>
                        <img src="/images/disabled.png" width={40} alt="disability" />
                </div>
            </div>
            
        </>
    );
}

export default Map;