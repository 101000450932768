import { useTranslation } from 'react-i18next'

function LangButton({img, language}) {

    const { i18n } = useTranslation()

    const changeLang = ()=>{
        i18n.changeLanguage(language.toLowerCase())
    }
    return (
        <div
        style={{
            width:"110px",
            marginInline:"50px",
            border:"6px solid white"
        }}
        onClick={changeLang}
        className="flex rounded-full overflow-hidden aspect-square">
            <img src={img} className="object-cover" alt={language}/>
        </div>
    );
}

export default LangButton;