function TopWidget({text, icon, disability}) {
    return (
        <div style={{
            fontSize:"50px",
            color:"inherit"
        }}>
            { icon !== undefined ? <img className={`${!disability ? "invert" : null} w-14 mr-4 float-left`} src={icon} alt="oras"/>: null}
            {text}
        </div>
    );
}

export default TopWidget;