import React, { createContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './pages/Home/Home'
import Overlay from './pages/Overlay/Overlay'
import Map from './pages/Home/views/Map/Map'
import Location from './pages/Home/views/LocationInfo/Location'
import Page from './pages/Home/views/Page/Page';
import AudioGuide from './pages/Home/views/AudioGuide/AudioGuide'
import Classter from './pages/Home/views/Classter/Classter'
import { useEffect } from 'react';

export const DisablilityContext = createContext()

function App() {
  
  const [disability, setDisability] = useState(false);

  useEffect(()=>{
    function handleKeyDown(e) {
      if (e.altKey === true && e.shiftKey === true && e.which === 191) {
          alert("išvalomas cahc'as");
          caches.keys().then((keyList) =>
          Promise.all(
            keyList.map((key) => {
                console.log(`cleared cahce ${key}`)
                return caches.delete(key);
            })
          ));
          window.location.reload();
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
      
    }
  }, []);

  return (
    <>
    <DisablilityContext.Provider value={{
      disability,
      setDisability
    }}>
      <div className="flex text-justify items-center class-cover fixed inset-0 bg-white z-50">
              <div className=" w-full text-center text-gray-700" style={{fontSize:'3em'}}> Aplikacija nepasiekiama, susisiekite su administratoriumi</div>
      </div>
      <Router>
        <Routes>
              <Route path="/" index element={<Overlay/>}/>
              <Route path="/main" element={<Home/>}>
                  <Route path="" index element={<Map/>} />
                  <Route path="location/:id"  element={<Location/>} />
                  <Route path="audio" element={<AudioGuide/>} />
                  <Route path="classter" element={<Classter/>} />
                  <Route path="page/:identifier"  element={<Page/>} />
              </Route>
        </Routes>
      </Router>
    </DisablilityContext.Provider>
    </>
  );
}

export default App;
