import Button from './components/Button'
import Widget from './components/Widget'
import LangButton from './components/LangButton'
import axios from 'axios';
import { useEffect, useState, useContext} from 'react';
import ProgressiveImage from '../../components/ProgressiveLoad';
import moment from 'moment';
import { DisablilityContext } from '../../App'
import { useTranslation } from 'react-i18next';

function Overlay() {
    const [temp, setTemp] = useState(10);
    const [icon, setIcon] = useState("01.png");
    const [time, setTime] = useState("17:00");
    const date = moment().format('YYYY MM DD');

    const { disability, setDisability } = useContext(DisablilityContext);
    const { t } = useTranslation();
    
    useEffect(() => {
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=55.70369056395698&lon=21.139349958742493&appid=e4633baf0f18b1956bf05a8e28c277b1&units=metric`

        
        const getWeather = ()=>{
            axios.get(url).then(res => {
                setTemp(Math.ceil(res.data.main.temp))
                const icon = res.data.weather[0].icon.replace('n', '').replace('d', '')
                setIcon(`${icon}.png`);
            })
            setTime(moment().format('HH:mm'))
        }

        getWeather()
        
        const interval = setInterval(() => {
            getWeather();
        }, 60000);

        return () => clearInterval(interval);
        
    }, [temp, setTemp]);

    return (
        <div className="fixed inset-0">
            <ProgressiveImage src="/images/over.jpg" placeholder="/images/tiny.jpg" width="1080px" height="1920px"/>
            <div style={{
                top: "80px"
            }} className='absolute w-full inline-flex justify-around'>
                <Widget text={date}/>
                <Widget text={time}/>
            </div>

            <div style={{
                top:"300px"
            }} className='absolute w-full flex text-center flex-col justify-center'>
                
                <div>
                    <span className='w-60'>
                        <img className='m-auto' style={{
                            maxWidth:'150px'
                        }} src={`images/weather_icons/${icon}`} alt="sun" />
                    </span>
                </div>
                <div style={{
                    fontSize:'95px'
                }}
                className='text-white drop-shadow'>
                    {temp}°C
                </div>

            </div>
            <div style={!disability ? {top:"820px"}: {top:"1250px"}}className='absolute w-full'>
                <div className='inline-flex w-full justify-center flex-wrap'>
                    <Button url="main" text={t("Informacija turistams")}/>
                    <Button url="main/audio" text={t("AUDIOGIDAS PO PRIEKULE")}/>
                    <Button url="main/classter" text={t("PAMARIO TURIZMO KLASTERIS")}/>
                </div>
                <div className='inline-flex mt-16 w-full justify-center'>
                        <LangButton img='svg/languages/lt.svg' language="LT"/>
                        <LangButton img='svg/languages/en.svg' language="EN"/>
                </div>
            </div>

            <div onClick={()=>{
                    setDisability(disability ? false : true);
                }} className='rounded-full w-24 flex justify-center items-center absolute right-10 float-right aspect-square' style={{
                    background:'#4190CE',
                    border: "6px solid white",
                    bottom: "14px"
                }}>
                <img src="/images/disabled.png" width={40} alt="disability" />
            </div>
        </div>
    );
}

export default Overlay;