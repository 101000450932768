import GetCacheData from "../../../../helpers/GetData";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

function Page() {

    const { identifier } = useParams()
    const navigate = useNavigate()
    const [page, setPage] = useState({});
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();

    const endpoint = `${process.env.REACT_APP_BACKEND_HOST}/admin/pages/api/getPage?id=${identifier}`

    useEffect(() => {
        setLoading(true);
        GetCacheData(endpoint, 'page').then(data=>{
            setPage(data);
            setLoading(false);
        }).catch(e=>{
            console.error(e);
        });

    }, [endpoint, setLoading, setPage, navigate]);

    if(loading) {
        return (
            <div className="pt-10 pb-10 over">
            <div className="bg-white p-5 rounded-lg relative pageContent overflow-hidden" style={{
                height:"1470px"
            }}>

                <div className={`absolute flex w-full h-full bg-white items-center justify-center loader`} style={{
                    zIndex: 999,
                    fontSize: "3em",
                    pointerEvents: "none"
                }}>
                    <img src="/images/loader.gif" alt="loading" />
                </div>
            </div>
            </div>
        );
    }

    return (
        <div className="pt-10 pb-10">
            <div className="bg-white p-5 rounded-lg pageContent overflow-y-auto" style={{
                height:"1470px"
            }} dangerouslySetInnerHTML={{ __html: page.translations[i18n.language.toLowerCase()] === undefined ? t("Informacija ruošiama") : page.translations[i18n.language.toLowerCase()]}}/>
        </div>
    );
}

export default Page;