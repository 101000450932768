import ProgressiveImage from "../../../../components/ProgressiveLoad";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from "react-i18next";

function Locations() {

    let { id } = useParams();
    const [location, setLocation] = useState({});
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();
    useEffect(() => {
        const endpoint = `/admin/places/api/getPlace?id=${id}`

        const getData = async () => {
            return axios.get(process.env.REACT_APP_BACKEND_HOST+endpoint)
        }
        getData().then(res => {

            setLocation(res.data)
            setLoading(false)
        })
        
    }, [id, setLocation, setLoading]);

    if(loading) {
        return(
        <div className="pt-6" style={{height: '1500px'}}>
             <Skeleton style={{
                width:"760px",
                height: "500px",
                display:"block",
                margin: 'auto',
             }} count={1} inline={false}/>

            <div style={{
                height:"800px",
                width: "1000px",
                background: "white",
                borderRadius: "25px",
                paddingTop: "20px"
            }}>
                <Skeleton style={{
                width:"800px",
                height: "40px",
                display:"block",
                margin: 'auto',
             }} count={1}/>
                <Skeleton style={{
                    width:"900px",
                    height: "10px",
                    display:"block",
                    margin: '10px auto',
                }} count={34} inline="false"/>
            </div>
            
        </div>
        )
    }

    return (
    <div className="pt-6" style={{
            height: '1550px'
    }}>
        
        <div>
        <ProgressiveImage className="m-auto rounded-lg" src={process.env.REACT_APP_BACKEND_HOST+location.image.url} style={{
                maxWidth:"760px",
                maxHeight: "500px"
            }} width={location.image.width} height={location.image.height} placeholder={process.env.REACT_APP_BACKEND_HOST+location.image.thumbnail}/>
        </div>

        <div style={{
            borderRadius: "25px",
            height:"800px"
        }} className="bg-white mt-6 px-6 py-3">
            <h1 className="text-center py-2" style={{
                fontSize: "40px",
                color: "#649BD1"
            }}>
                {location.names[i18n.language.toLowerCase()]}
            </h1>
            <div style={{
                fontSize:"28px",
                lineHeight:"120%",
                height:"640px"
            }}
            className="myriad-light px-5 overflow-y-auto inline-block" dangerouslySetInnerHTML={{__html: location.descriptions[i18n.language.toLowerCase()]}}>
            </div>
        </div>
        {location.show_qr && location.qr_url !== "" ? <div className="bg-red-500 mt-10">
            <div className="float-right text-center">
            <img src={`${process.env.REACT_APP_BACKEND_HOST}/admin/places/gq/${location.id}`} style={{
                    width:"90px"
                }} className="m-auto" alt="guide" />
                <p className="text-2xl text-white">{t("Kaip nuvykti?")}</p>
            </div>
        </div> : null}
    </div>
    );
}

export default Locations;