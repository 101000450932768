import { Marker} from 'react-leaflet'
import { useNavigate } from 'react-router-dom';
import { useMap} from 'react-leaflet';
import { useEffect } from 'react';
import L from 'leaflet';
function AudioMarkers({markers, setLastPage}) {
    const navigate = useNavigate()
    const map = useMap()

    const features = L.featureGroup()

    useEffect(() => {
        if(Object.keys(features.getBounds()).length !== 0) {
            map.fitBounds(features.getBounds())
        }
    });
    return (
        <>
            {markers.map((marker, index)=>{
                const coords = marker.place.coordinates.split(',')
                if(isNaN(coords[0]) || isNaN(coords[1])) {
                    return null
                }
                const L = require('leaflet');

                const icon = L.icon({
                    iconUrl: process.env.REACT_APP_BACKEND_HOST+`/admin/paths/api/getMarker?color=F48225&text=${index+1}`,
                    iconSize: [52, 71],
                    iconAnchor: [26, 62]
                });
                return (
                    <Marker
                    key={index}
                    icon={icon}
                    position={[parseFloat(coords[0]), parseFloat(coords[1])]}
                    eventHandlers={{
                        click: (e) => {
                          navigate(`/main/location/${marker.place.id}`)
                          setLastPage('/main/audio')
                        },
                        add: (e) => {
                            // console.log("Added Layer:", e.target);
                            e.target.addTo(features);
                        }
                      }}
                    />
                )
            })}
        </>
    );
}

export default AudioMarkers;