import { MapContainer, TileLayer, Marker, FeatureGroup} from 'react-leaflet'
import { useEffect, useState, useContext} from 'react';
import GetCacheData from '../../../../helpers/GetData';

import { DisablilityContext } from '../../../../App'

import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';
import Categories from './components/Categories';
import { useOutletContext } from "react-router-dom";
import MapLoader from '../../components/MapLoader'
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
function Map() {
    const endpoint = "/admin/places/api/getActivePlaces?exempt=85e8f0d3aef94f980a1ba16f94862d15"
    const endpointCats = "/admin/places/api/getCategories?exempt=85e8f0d3aef94f980a1ba16f94862d15"

    const [markers, setMarkers] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    let { disability, setDisability } = useContext(DisablilityContext);
    const [setLastPage, setActivePage, setActiveName] = useOutletContext();
    const {t} = useTranslation();

    const [map, setMap] = useState(null);
    const [features, setFeatures] = useState(null);

    const ref = useCallback(node => {
        if(node !== null) {
            if(Object.keys(node.getBounds()).length !== 0) {
                setFeatures(node)
            }
        }
    },[])

    useEffect(() => {
        if(!map) return;
        if(!features) return;
        map.target.fitBounds(features.getBounds())
    }, [map, features]);

    useEffect(() => { 
        setLastPage('/')
        setActiveName(t('Lankytinos vietos'))
        setActivePage('/main')
        try{
            if (markers.length === 0){
                
                GetCacheData(process.env.REACT_APP_BACKEND_HOST+endpoint, 'PlacesMarkers').then(data=>{
                    setMarkers(data.data)
                });
            }
            if(categories.length === 0) {
                GetCacheData(process.env.REACT_APP_BACKEND_HOST+endpointCats, 'PlacesCategories').then(data=>{
                    setCategories(data);
                });
            }
        } catch (error){
            console.error(error); 
        } 
        
        
    }, [setMarkers, setCategories, setLastPage, setActivePage, markers, categories, t, setActiveName]);

    const navigate = useNavigate()
    return (
        <>
           {!disability ? <Categories categories={categories} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory}/> : null}
            {/* <-----------map -------------/> */}
            <div className='w-full pt-4' style={{
                height:"1473px"
            }}>
                <MapContainer whenReady={setMap} style={{height:"1327px"}} minZoom={12} className="rounded-md" center={[55.55485537748448, 21.34834242595446]} zoom={12}>
                    <MapLoader markers={markers}/>
                    <TileLayer
                    attribution=''
                    url="https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=Tzwx6Sh4T1b3h9jh9rwCuXjiwq2ag4nMxO9RrlWj37CSxdeNSxYTrwIRfUsVxVpY"
                    />
                    <FeatureGroup ref={ref}>
                        {markers.map((marker, index)=>{ 
                            
                            let show = false;
                            let icona = marker.categories[Object.keys(marker.categories)[0]]["map_icon"];
                            if(selectedCategory == null){
                                show = true;
                            } else {
                                for (const cat of Object.keys(marker.categories)) {
                                    if (marker.categories[cat]['id'] === selectedCategory){
                                        show = true;
                                        if(marker.categories[cat]['map_icon'] !== undefined){
                                            icona = marker.categories[cat]['map_icon']
                                        }
                                        break;
                                    }
                                }
                            }

                            if(!show) {
                                return null
                            }

                            const coords = marker.coordinates.split(',')
                            const L = require('leaflet');
                            const icon = L.icon({
                                iconUrl: process.env.REACT_APP_BACKEND_HOST+icona.url,
                                iconSize: [icona.width,icona.height],
                                iconAnchor: [icona.width/2, icona.height]
                            });

                            return (
                                <Marker
                                key={index}
                                icon={icon}
                                position={[parseFloat(coords[0]), parseFloat(coords[1])]}
                                eventHandlers={{
                                    click: (e) => {
                                      setLastPage('/main')
                                      navigate(`/main/location/${marker.id}`)
                                    },
                                  }}
                                />
                            )
                        })}
                    </FeatureGroup>
                </MapContainer>
                <div onClick={()=>{
                    setDisability(disability ? false : true);
                }} className='rounded-full w-24 flex justify-center items-center float-right mt-5 aspect-square' style={{
                    background:'#4190CE',
                    border: "6px solid white"
                }}>
                    <img src="/images/disabled.png" width={40} alt="disability" />
                </div>
            </div>
            {disability ? <Categories categories={categories} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory}/> : null}
        </>
    );
}

export default Map;