
function CategoryButton({img, text, setCatId, catid, selected, color}) {
    
    const __click = () => {
        setCatId(catid)
    }

    const newShade = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

    return (
        <div
        onClick={__click} className={`rounded-full text-center leading-none flex px-10 mx-2 justify-center items-center text-white text-xl`} style={{
                height:"50px",
                background: color,
                outline: selected === catid ? `3px solid ${newShade(color, 30)}` : "none"
            }}>
            {img ? <img src={img} style={{width:"35px"}} className="float-left mr-3 aspect-square" alt="ikona"/> : null}
            {text}
        </div>
    );
}

export default CategoryButton;