import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet'

function ClassterMarkers({markers, setLastPage}) {
    const navigate = useNavigate()

    const map = useMap()

    const features = L.featureGroup()

    useEffect(() => {
        if(Object.keys(features.getBounds()).length !== 0) {
            map.fitBounds(features.getBounds())
        }
    });
    return (
        <>
            {markers.map((marker, index)=>{
                const coords = marker.coordinates.split(',')
                const L = require('leaflet');
                const icon = L.icon({
                    iconUrl: process.env.REACT_APP_BACKEND_HOST+`/admin/paths/api/getMarker?color=FCC337&text=${index+1}`,
                    iconSize: [52, 71],
                    iconAnchor: [26, 62]
                });
                
                return (
                    <Marker
                    key={index}
                    icon={icon}
                    position={[parseFloat(coords[0]), parseFloat(coords[1])]}
                    eventHandlers={{
                        click: (e) => {
                          navigate(`/main/location/${marker.id}`)
                          setLastPage("/main/classter")
                        },
                        add: (e) => {
                            e.target.addTo(features);
                        }
                      }}
                    />
                )
            })}
        </>
    );
}

export default ClassterMarkers;