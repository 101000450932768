function Widget({text}) {
    return (
        <div style={{
            marginInline: "50px",
            fontSize: "65px"
        }} className="text-white myriad-semi-bold drop-shadow tracking-normal">
            {text}
        </div>
    );
}

export default Widget;