import { useNavigate } from "react-router-dom";

function MenuButton({text, url, activeButton, setActiveButton, bid}) {

    const navigate = useNavigate()
    return (
        <button onClick={()=>{
            setActiveButton(bid)
            navigate(url || "/main")
        }} style={
            {
                width: "220px",
                height: "80px",
                // border: "3px solid white",
                borderRadius: "15px"
            }
            } className={`text-white text-2xl menuButton ${activeButton === bid ? "active": ""}`} type="button">
            {text}
        </button>
    );
}

export default MenuButton;