
export default async function GetCacheData(url, name){

    let cachedData = await getCachedData(name, url);

    if (cachedData) {
        // console.log(`data returned from memory | endpoint: ${url}`)
        return cachedData;
    }
    
    const cacheStorage = await caches.open(name);
    await fetch(url).then()
    await cacheStorage.add(url);
    cachedData = await getCachedData(name, url);
    // console.log(`data returned from API call | endpoint: ${url}`)
    return cachedData;
}

async function getCachedData(name, url) {
    const cacheStorage = await caches.open(name);
    const cachedResponse = await cacheStorage.match(url);
  
    if (!cachedResponse || !cachedResponse.ok) {
      return false;
    }
  
    return await cachedResponse.json();
}